import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getScores,
  proportionComplete } from '../reducer';
import '../../cows/components/cow-score-card.scss';
import './rate-my-animal.scss';
import { updateDemoScore } from '../actions';
import SelectableFactor from './SelectableFactor';
import history from '../../goodBeefIndex/gbiHistory';
import { getCategory, gbiElements, getName } from '../../admin/scoreDefinitions/util';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';
import { Helmet } from 'react-helmet';

const mapDispatchToProps = dispatch => ({
  select: (attribute, value) => dispatch(updateDemoScore(attribute, value))
});

const mapStateToProps = state => ({
  scores: getScores(state),
  completed: proportionComplete(state),
  scoreDefinitions: getScoreDefinitions(state)
});

const factorMargin = 20;

class RateMyAnimal extends Component {
  constructor (props) {
    super(props);
    const attributes = Object.keys(this.props.scores);
    let factorWidth = this.getFactorWidth();
    const mobile = window.innerWidth <= 700;
    const carouselMargin = (window.innerWidth - factorWidth) / 2;
    const carouselWidth = (factorWidth * attributes.length) +
      (factorMargin * attributes.length) + carouselMargin;
    const index = this.getIndex();
    this.state = {
      attributes,
      index,
      factorWidth,
      carouselWidth,
      carouselMargin,
      mobile,
      dragging: false,
      touchStartPos: null,
      touchCurrentPos: null
    };
    this.handleResize = this.handleResize.bind(this);
    this.currentAttribute = this.currentAttribute.bind(this);
    this.navigateByAttributeIndex = this.navigateByAttributeIndex.bind(this);
  }
  componentDidMount () {
    window.addEventListener('resize', this.handleResize);
    window.scrollTo({ top: 0 });
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
  }
  componentDidUpdate (prevProps) {
    if (prevProps.completed < 1 && this.props.completed === 1) {
      history.push('/animal-spec');
    }
  }
  getFactorWidth () {
    let factorWidth = window.innerWidth / 2;
    if (window.innerWidth < 501) {
      factorWidth = window.innerWidth - 40;
    } else if (window.innerWidth < 701) {
      factorWidth = window.innerWidth * 0.8;
    } else if (window.innerWidth < 901) {
      factorWidth = window.innerWidth * 0.7;
    }
    return factorWidth;
  }
  handleResize () {
    const attributes = this.state.attributes;
    const factorWidth = this.getFactorWidth();
    const mobile = window.innerWidth <= 700;
    const carouselMargin = (window.innerWidth - factorWidth) / 2;
    const carouselWidth = (factorWidth * attributes.length) +
      (factorMargin * attributes.length) + carouselMargin;
    this.setState({ factorWidth, carouselMargin, carouselWidth, mobile });
  }
  handleTouchStart (event) {
    this.setState({
      dragging: true,
      touchStartPos: event.touches[0].clientX,
      touchCurrentPos: event.touches[0].clientX
    });
  }
  handleTouchMove (event) {
    if (this.state.dragging) {
      const touchCurrentPos = event.touches[0].clientX;
      this.setState({ touchCurrentPos });
    }
  }
  handleTouchEnd () {
    const distance = this.state.touchCurrentPos - this.state.touchStartPos;
    if (this.state.dragging && distance > 100) {
      if (this.state.index > 0) {
        this.navigateByAttributeIndex(this.state.index - 1);
      }
    } else if (this.state.dragging && distance < -100) {
      const scores = Object.keys(this.props.scores);
      if (this.state.index < (scores.length - 1)) {
        this.navigateByAttributeIndex(this.state.index + 1);
      }
    }
    this.setState({
      dragging: false,
      touchStartPos: null,
      touchCurrentPos: null
    });
  }
  handleTouchCancel () {
    this.setState({
      dragging: false,
      touchStartPos: null,
      touchCurrentPos: null
    });
  }
  currentAttribute () {
    return this.state.attributes[this.state.index];
  }
  getIndex () {
    const section = this.props.match.params.section;
    const attributes = Object.keys(this.props.scores);
    for (let i = 0; i < attributes.length; i++) {
      if (
        attributes[i] === section ||
        getCategory(attributes[i], this.props.scoreDefinitions) === section) {
        return i;
      }
    }
    return 0;
  }
  scrollForward () {
    const index = this.state.index + 1;
    if (index < Object.keys(this.props.scores).length) {
      this.setState({ index });
      window.scrollTo({ top: 0 });
    }
  }
  navigateByAttributeIndex (index) {
    this.setState({ index });
    window.scrollTo({ top: 0 });
    const old = this.props.match.params.section;
    const current = this.state.attributes[index];
    history.push(`${this.props.match.url.replace(old, current)}`);
  }
  renderFactors () {
    return Object.keys(this.props.scores).map((attribute, index) => {
      const currentScore = this.props.scores[attribute];
      const select = newScore => {
        this.props.select(attribute, newScore);
        const i = index + 1;
        if (newScore !== null && i < Object.keys(this.props.scores).length) {
          this.navigateByAttributeIndex(i);
        }
      };
      const width = `${this.state.factorWidth}px`;
      const inView = this.state.attributes[this.state.index] === attribute;
      let show = () => this.navigateByAttributeIndex(index);
      const props = {
        attribute, select, currentScore, width, inView, show, index
      };
      return <SelectableFactor key={ attribute } { ...props } />;
    });
  }
  renderMobileAttributeMenu () {
    const currentElement = getCategory(
      this.currentAttribute(), this.props.scoreDefinitions
    );
    const attributes = this.state.attributes.map((attribute, index) => {
      if (getCategory(attribute, this.props.scoreDefinitions) !==
        currentElement) return null;
      let className = 'mobile-attribute-menu-item';
      if (typeof this.props.scores[attribute] === 'number') {
        className += ' complete';
      }
      if (index === this.state.index) {
        className += ' active';
      }
      return (
        <li onClick={() => this.navigateByAttributeIndex(index) }
          key={ attribute }
          data-cy={ `factor-menu-item-${index}`}
          className={ className }>
          <div className="inner"></div>
        </li>
      );
    });
    return (
      <ul className="mobile-attribute-menu">
        { attributes }
      </ul>
    );
  }
  renderProgressMenu () {
    const items = gbiElements.map((element, index) => {
      let className = 'progress-menu-item';
      if (element ===
        getCategory(this.currentAttribute(), this.props.scoreDefinitions)) {
        className += ' active';
      }
      const handleClick = () => this.navigateByAttributeIndex(index * 9 + 1);
      return (
        <li className={ className }
          data-cy={ `element-menu-item-${index + 1}`}
          onClick={ handleClick }
          key={ element }>
          <div className="inner"></div>
        </li>
      );
    });
    const lastClass = this.props.completed === 1 ? 'progress-menu-item' :
      'progress-menu-item hidden';
    return (
      <ul cy="element-menu" className="progress-menu">
        <li className='progress-menu-item'
          data-cy="element-menu-item-0"
          onClick={ () => history.push('/rate-my-animal') } >
          <div className="inner"></div>
        </li>
        { items }
        <li className={ lastClass }
          data-cy="element-menu-item-4"
          onClick={ () => history.push('/animal-spec') } >
          <div className="inner"></div>
        </li>
      </ul>
    );
  }
  render () {
    let translateX = (this.state.factorWidth + factorMargin) *
      (this.state.index * -1) + this.state.carouselMargin;
    let width = (this.state.factorWidth + factorMargin) *
      this.state.attributes.length + this.state.carouselMargin;
    const transform = `translateX(${translateX}px)`;
    width += 'px';
    let title =
      getCategory(this.currentAttribute(), this.props.scoreDefinitions)
      + ' quality';
    const attributeTitle =
      getName(this.currentAttribute(), this.props.scoreDefinitions);
    const pageTitle = `Try out the GoodBeef Index. Score for ${attributeTitle}`;
    const metaDescription = `GoodBeef Index measures and grades beef quality. 
      See how simple it is to score your animal for
      the GoodBeef factor "${title}"
    `;
    return (
      <div id="rate-my-animal-section" className="gbi-content">
        <Helmet>
          <title>{ pageTitle }</title>
          <meta name="description" content={ metaDescription } />
          <link rel="canonical"
            href={ `http://goodbeefindex.org${this.props.location.pathname}` }/>
        </Helmet>
        <h5 className="try-it-out">try it out</h5>
        <h1 className="element">{ title }</h1>
        { this.renderMobileAttributeMenu() }
        <div
          className="factors"
          onTouchCancel={ this.handleTouchCancel.bind(this) }
          onTouchStart={ this.handleTouchStart.bind(this) }
          onTouchEnd={ this.handleTouchEnd.bind(this) }
          onTouchMove={ this.handleTouchMove.bind(this) }
          style={{ transform, width }}>
          { this.renderFactors() }
        </div>
        {/* { this.renderAttributeMenu() } */}
        { this.renderProgressMenu() }
      </div>
    );
  }
}

RateMyAnimal.propTypes = {
  select: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      section: PropTypes.string
    }),
    url: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  completed: PropTypes.number,
  scores: PropTypes.object,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(RateMyAnimal);
