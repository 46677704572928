import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TickIcon from '../../util/icons/components/TickIcon';
import {
  getSignificance,
  getScoreCard,
  getName
} from '../../admin/scoreDefinitions/util';
import { getScoreDefinitions } from '../../admin/scoreDefinitions/reducer';

const mapStateToProps = state => ({
  scoreDefinitions: getScoreDefinitions(state)
});

class SelectableFactor extends Component {
  constructor (props) {
    super(props);
    this.state = {
      scoreTouched: null,
      touchStartX: null,
      touchStartY: null,
      touchCurrentX: null,
      touchCurrentY: null
    };
  }
  handleTouchMove (event) {
    this.setState({
      touchCurrentX: event.touches[0].clientX,
      touchCurrentY: event.touches[0].clientY
    });
  }
  handleTouchEnd (event) {
    if (Math.abs(this.state.touchStartX - this.state.touchCurrentX) < 20 &&
    Math.abs(this.state.touchStartY - this.state.touchCurrentY) < 20) {
      const val = this.state.scoreTouched === this.props.currentScore ?
        null : this.state.scoreTouched;
      this.props.select(val);
    }
    this.clearTouch();
    event.preventDefault();
  }
  clearTouch () {
    this.setState({
      scoreTouched: null,
      touchStartX: null,
      touchStartY: null,
      touchCurrentX: null,
      touchCurrentY: null
    });
  }
  renderScores () {
    const scores = getScoreCard(
      this.props.attribute, this.props.scoreDefinitions
    ).map(score => {
      let className = 'score';
      let handleClick = () => this.props.select(score.score);
      const handleTouchStart = event => {
        this.setState({
          touchStartX: event.touches[0].clientX,
          touchStartY: event.touches[0].clientY,
          touchCurrentX: event.touches[0].clientX,
          touchCurrentY: event.touches[0].clientY,
          scoreTouched: score.score
        });
      };
      if (score.score === this.props.currentScore) {
        className += ' selected';
        handleClick = () => this.props.select(null);
      }
      if (!this.props.inView) {
        handleClick = this.props.show;
      }
      return (
        <li key={score.score }
          onClick={ handleClick }
          onTouchStart={ handleTouchStart }
          onTouchMove={ this.handleTouchMove.bind(this) }
          onTouchEnd={ this.handleTouchEnd.bind(this) }
          onTouchCancel={ this.clearTouch.bind(this) }
          className={ className }>
          <div className="tick-box">
            <TickIcon />
          </div>
          <div className='number'>{ score.score }</div>
          <div className='text'>{ score.definition }</div>
        </li>
      );
    });
    return scores;
  }
  render () {
    const title = getName(this.props.attribute, this.props.scoreDefinitions);
    let className = this.props.inView ? 'factor in-view' : 'factor';
    return (
      <article className={ className } style={{ width: this.props.width }}>
        <div className="head">
          <h4 className='title'>{ title }</h4>
          <p className='significance'>
            { getSignificance(
              this.props.attribute,
              this.props.scoreDefinitions) }
          </p>
        </div>
        <ul data-cy={ `factor-${this.props.index}` }
          className='scores'>
          { this.renderScores() }
        </ul>
      </article>
    );
  }
}

SelectableFactor.propTypes = {
  attribute: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  currentScore: PropTypes.number,
  width: PropTypes.string.isRequired,
  inView: PropTypes.bool.isRequired,
  show: PropTypes.func.isRequired,
  index: PropTypes.number,
  scoreDefinitions: PropTypes.object
};

export default connect(mapStateToProps)(SelectableFactor);
